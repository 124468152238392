// extracted by mini-css-extract-plugin
export var aboutText = "about_us-module--aboutText--rQEuI";
export var abouttext = "about_us-module--abouttext--r+Tv7";
export var heading = "about_us-module--heading--C4Iln";
export var portraits = "about_us-module--portraits--IRs7s";
export var profile = "about_us-module--profile--96GGx";
export var profileContainer = "about_us-module--profile-container--kvMfL";
export var profileDesc = "about_us-module--profile-desc--ycpcb";
export var profileElement = "about_us-module--profile-element--f5xSS";
export var profileInfo = "about_us-module--profile-info--L5d0C";
export var profileName = "about_us-module--profile-name--MKtDg";