import React from "react"

import { StaticQuery, graphql } from "gatsby"

import AppBase from "../util/app-base"

import Header from "../components/header"
import Footer from "../components/footer"
import FullScreen from "../components/landing-page/full-screen"
import {
  InternalRainbowLink,
  ExternalRainbowlink,
} from "../components/rainbow-link"
import RainbowHero from "../components/landing-page/rainbow-hero"
import ScrollDown from "../components/landing-page/scroll-down"
import School from "../components/landing-page/school"
import ClaimWithImage from "../components/landing-page/claim-with-image"
import DonateBox from "../components/landing-page/action-box"
import Heart from "../images/heart.inline.svg"
import AboutUs from "../components/landing-page/about_us"
import SiteEnclosingWrapper from "../components/site-enclosing-wrapper"
import SectionSeperator from "../components/section-seperator"
import DownloadSection from "../components/landing-page/download-section"
import MapSection from "../components/landing-page/map-section"

// TODO: Appearance animations after flag flies in

export default () => (
  <AppBase title="FLAGincluded - Home">
    <SiteEnclosingWrapper>
      <FullScreen>
        <Header
          button={
            <InternalRainbowLink
              title="Spenden"
              href="/spenden"
              fillWidth={false}
            />
          }
        />
        <RainbowHero />
        <ScrollDown />
      </FullScreen>
      <ClaimWithImage
        image={<School />}
        claim="Wir arbeiten mit Schulen in ganz Österreich zusammen, um während des Pride-Months LGBTIQ-Flaggen zu hissen. Queere Sichtbarkeit ist der beste Weg, um allen Schüler*innen kompromisslose Selbstakzeptanz zu vermitteln."
        actionItem={
          <InternalRainbowLink
            title="Mitmachen"
            desc="Für Schulen"
            href="/anmeldung"
            style={{ maxWidth: "550px" }}
          />
        }
      />
      <SectionSeperator />
      <MapSection />
      <SectionSeperator />
      <DownloadSection
        smallTitle="Downloads"
        title="Hier findest du unsere Ressourcen zum Projekt."
        linkText="Zur Downloads-Seite"
        link="/downloads"
      />
      <SectionSeperator />
      <DonateBox
        icon={<Heart />}
        text="Um dieses Projekt zu ermöglichen, sind wir auf Spenden angewiesen. Je mehr Spenden wir erhalten, desto bunter werden die Schulen Österreichs!"
        actionItem={
          // FIXME: Looks squashed in Chrome
          <InternalRainbowLink title="Spenden" href="/spenden/" />
        }
      />
      <StaticQuery
        query={graphql`
          query {
            allStrapiTeamMember {
              edges {
                node {
                  Name
                  Description
                  Portrait {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FIXED, width: 150, height: 150)
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const profiles = data.allStrapiTeamMember.edges.map(({ node }) => ({
            name: node.Name,
            description: node.Description,
            image: node.Portrait.localFile.childImageSharp.gatsbyImageData,
          }))

          return <AboutUs profiles={profiles} />
        }}
      />
    </SiteEnclosingWrapper>
    <Footer />
  </AppBase>
)
