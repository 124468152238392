import React from "react"
import * as styles from "./about_us.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"

import { Center } from "../layout"
import SectionSeperator from "../section-seperator"

export const ProfileElement = ({ image, name, role }) => {
  return (
    <div className={styles.profileElement}>
      <div className={styles.profileContainer}>
        <GatsbyImage
          className={styles.profile}
          image={image}
          alt={name}
          layout="fixed"
        />
      </div>
      <div className={styles.profileInfo}>
        <h2 className={styles.profileName}>{name}</h2>
        {role && <p className={styles.profileDesc}>{role}</p>}
      </div>
    </div>
  )
}

export default ({ profiles }) => (
  <div className={styles.container}>
    <Center>
      <SectionSeperator style={{ margin: "80px auto" }} />
    </Center>
    <h1 className={styles.heading}>Über Uns</h1>
    <div className={styles.portraits}>
      {profiles.map(profile => (
        <ProfileElement
          image={profile.image}
          name={profile.name}
          role={profile.description}
        />
      ))}
    </div>
    <p className={styles.aboutText}>
      FLAGincluded ist ein von jungen Aktivist*innen ins Leben gerufenes Projekt
      der HOSI Wien. Als Veranstalterin der Regenbogenparade ist der HOSI Wien
      sehr daran gelegen, den Regenbogen dorthin zu tragen, wo er am
      dringendsten gebraucht wird: In die Schulen.
    </p>
  </div>
)
